function checkConfirmation() {
  var successPage = $("#registrations-success");

  if (successPage.length > 0) {
    $.ajax({
      url: "/users/check_confirmation",
      method: "GET",
      success: function(data) {
        if (data.redirect_path === null) {
          setTimeout(checkConfirmation, 3000);
        } else {
          window.location = data.redirect_path
        }
      }
    });
  }

  // $(".alert-info").fadeTo(2000, 500).slideUp(500, function(){
  //   $(".alert-info").slideUp(500);
  // });

  $('.contact_us_domain_name').height('95px');
  $('.errormessageclass').parent().height("95px");
  $('.requiremessage').parent().height("95px");
  $('.requiremessage').next().css("position", "absolute");
  $('.textarea-height').parent().height("120px");
  $('.test').parent().width("99.5%");
  $('.test').parent().height("120px");

}

export { checkConfirmation };
