import consumer from "./consumer"

const domainSuggestionChannel = consumer.subscriptions.create("DomainSuggestionChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected");
    $(document).trigger("searchConnected");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  search_for(data) {
    this.perform('search_for', { data })
  },

  renderSnippet(data) {
    this.perform('render_snippet', { data })
  },

  announce(domain_name) {
    // console.log(domain_name)
  },

  received(data) {
    // console.log(`#${data.room}`)
    
    // if (data.index) {
    //   console.log(data.index)
    // }
    // console.log(data)

    if ($(`#${data.room}`).length === 0 || $(`#search-result tbody.suggestions [data-domain='${data.domain_name}']`).length > 0 ) {
      return
    }


    if (data.index == 0) {
      console.log(data)
      if ($("#search-result .desktop-table tbody.suggestions tr:nth-child(0)").length > 0){
        $("#search-result .desktop-table tbody.suggestions tr:nth-child(0)").before(data.html.snippet);
        $("#search-result .domain-table-mobile tbody.suggestions tr:nth-child(0)").before(data.html.snippet_mobile);
      }
      else {
        $("#search-result .desktop-table tbody.suggestions").prepend(data.html.snippet);
        $("#search-result .domain-table-mobile tbody.suggestions").prepend(data.html.snippet_mobile);
      }
    }
    else if (data.index !== undefined) {
      console.log(data)
      $("#search-result .desktop-table tbody.suggestions tr:nth-child(" + data.index + ")").after(data.html.snippet);
      $("#search-result .domain-table-mobile tbody.suggestions tr:nth-child(" + data.index * 4 + ")").after(data.html.snippet_mobile);
    } else {
      $("#search-result .desktop-table tbody.suggestions").append(data.html.snippet);
      $("#search-result .domain-table-mobile tbody.suggestions").append(data.html.snippet_mobile);
    }
  }
});

export default domainSuggestionChannel;
