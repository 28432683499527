function dirty_page_handler(isFormChange) {
  var billingEditSection = $('.billing-edit-section');

  if (!billingEditSection.length) {
    return;
  }

  var batchUpdating = false;

  $(document).on('submit','form#batch_update_invoice', function() {
    batchUpdating = true;
 });

  // alert user when they try to refresh the page
  window.onbeforeunload = function() {
    var ifFormDirty = isFormChange && !batchUpdating;
    return ifFormDirty ? ifFormDirty : null;
  };

  // confirm user if they want to navigate to other page
  $(document).on('turbolinks:before-visit', function() {
    if (!isFormChange) {
      return;
    }
    return confirm('are you sure you want to leave the page without updating your changes?');
  });

  $(window).on('popstate', function() {
    if (!isFormChange) {
      return;
    }
    return confirm('are you sure you want to leave the page without updating your changes?');
  });
}

export { dirty_page_handler };
