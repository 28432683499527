var zxcvbn = require('zxcvbn');

function togglePassword() {
  $(".toggle-password").click(function(e) {
    e.preventDefault()

    $(this).toggleClass("fa-eye fa-eye-slash")
    var input = $(".registeration-user-password");


    if (input.attr("type") == "text") {
      input.attr("type", "password");
    } else {
      input.attr("type", "text");
    }
  })
}

function validatePassword() {
  $("form.reset-password-form").validate({
    rules: {
      'user[password]': {
        required: true,
        minlength: 6
      },
      'user[password_confirmation]': {
        required: true,
        equalTo: '#user_password'
      }
    },
    messages: {
      'user[password]': {
        minlength: '6 characters minimum'
      },
      'user[password_confirmation]': {
        equalTo: 'Password doesn\'t match'
      }
    }
  });
}

function passwordMetter(){
  togglePassword();
  validatePassword();

  var strength = {
    0: "Worst <i class='far fa-frown'></i>",
    1: "Bad <i class='far fa-frown'></i>",
    2: "Weak <i class='far fa-frown'></i>",
    3: "Good <i class='far fa-smile-beam'></i>",
    4: "Strong <i class='far fa-smile-beam'></i>"
  }

  var passwordInput = $("input.registeration-user-password");
  var meter = $("#password-strength-meter");
  var feedback = $("#password-strength-text");

  passwordInput.on("input", function(){
    console.log('change');
    var val = $(this).val();
    var result = zxcvbn(val);
    meter.attr("value", result.score)
    if(val !== "") {
      feedback.html("Strength: " + "<strong>" + strength[result.score] + "</strong>" + "<span class='feedback'>" + result.feedback.warning + " " + result.feedback.suggestions + "</span");
    }
    else {
      feedback.html("");
    }
  })
}

export { passwordMetter };
