import swal from 'sweetalert'
import { updateCounter, requestCreate, requestRemove } from './bookmark'

const Switch = require('weatherstar-switch')

function updateCartBadge(newCounter) {
  updateCounter($(".cart-item-counter"), newCounter)
}

function buyNow() {
  $(document).on('click', '.btn-buy-now', function(e) {
    e.preventDefault()

    const $elem = $(this)
    requestCreate($elem, response => {
      updateCartBadge(response.counter)
      window.location = $elem.data("next")
    })
  })
}

function toggleCart() {
  $(document).on("click", ".add-to-cart", function(e) {
    const $elem = $(this);
    const parent = $(this).parent();
    const cssClass = 'added_to_cart';
    const isAdded = $elem.hasClass(cssClass);

    if(isAdded) {
      requestRemove($elem, response => {
        $elem.addClass('d-none');
        parent.find('.add-to-cart-outline').removeClass('d-none');
        updateCartBadge(response.counter)
      });
    } else {
      requestCreate($elem, response => {
        $elem.addClass('d-none');
        parent.find('.add-to-cart-solid').removeClass('d-none');
        parent.find('.add-to-cart-solid').data('id', response.item.id);
        updateCartBadge(response.counter)
      });
    }

    // Used in brokerage modal
    $("a.add-to-cart-brokerage").toggleClass("disabled")

    $("#broker-service-info").modal("hide")
    // Brokerage find out more page
    $('.add-to-card-msg').removeClass('d-none');
  })
}

function removeCartItem() {
  $("a.remove-order-item").on("click", function(e){
    e.preventDefault();
    var link = $(this);
    var content = document.createElement("p");
    content.innerHTML = "Are you sure you want to <b>remove</b> the domain <b>"+ link.data('name') +"</b> from your basket?";

    swal({
      content: content,
      icon: "warning",
      buttons:{
        cancel: true,
        confirm:{
          text: "Yes, confirm",
          value: true
        }
      }
    }).then(function(isConfirm){
      if(isConfirm){
        $.ajax({
          url: link.data("href"),
          type: 'DELETE',
          dataType: 'json',
        }).done(function(data, textStatus, jQxhr) {
          updateCartBadge(data.counter);
          link.closest(".order-item").remove();
          $(".total").text(data.total)

          if ($(".order-items-wrapper .order-item").length === 0) {
            $(".card.empty-basket").removeClass("d-none");
            $(".cart-items-container").remove();
            window.location.reload()
          }
        }).fail(function(jqXhr, textStatus, errorThrown) {
          console.log(textStatus);
        });
      }
    });
  })
}

function requestUpdateOrderItem(url, orderItem) {
  return $.ajax({
    url: url,
    type: 'PUT',
    data: { order_item: orderItem },
    dataType: 'json'
  });
}

function updateOrderItem(elem, orderItem) {
  const url = elem.dataset.href;
  requestUpdateOrderItem(url, orderItem)
    .done( data => {
      console.log(data);
      const itemId = data.item.id;
      $(`.subtotal-${itemId}`).text(data.sub_total);
      $('.total').text(data.total);
    })
    .fail((_, textStatus) => {
      console.log(textStatus);
    });
}

function onUpdateQuantity() {
  $("select.order-item-quantity").on("change", function() {
    const orderItem = { quantity: $(this).val() }
    updateOrderItem(this, orderItem);
  })
}

function onUpdatePrivacy() {
  $('.js-privacy-switch').each(function() {
    new Switch(
      this, {
        size: 'small',
        disabled: this.disabled,
        onChange: () => updateOrderItem(this, { privacy: this.checked })
      }
    );
  });
}


function onUpdateSSL() {
  $('.js-ssl-switch').each(function() {
    new Switch(
      this, {
        size: 'small',
        disabled: this.disabled,
        onChange: () => updateOrderItem(this, { ssl: this.checked })
      }
    );
  });
}

function handleBrokerageModal() {
  $(document).on("click", ".open-brokerage-service-fee", function(e) {
    e.preventDefault();
    const rowID = new Date().getTime();

    $(e.target).closest("tr").attr("id", rowID)
    $("#broker-service-info").modal("show")
    window.workingDomain = rowID
    
    $("a.add-to-cart-brokerage").removeClass("disabled")
    $(".already-in-basket-message").addClass("d-none")

    if (!$(e.target).closest("tr").find("svg.added_to_cart").hasClass('d-none')) {
      const domainName = $("#" + window.workingDomain).find("[data-domain]").data("domain")

      console.log('already in the basket')
      $(".already-in-basket-message").html(`Brokerage fee for <strong>${domainName}</strong> is already in your <a href='/basket'>basket</a>.`)
      $(".already-in-basket-message").removeClass("d-none")
      $("a.add-to-cart-brokerage").addClass("disabled")
    }
  })

  $(".add-to-cart-brokerage").on("click", function(e) {
    e.preventDefault()
    $("#" + window.workingDomain).find(".add-to-cart-outline").trigger("click")
  })
}

function addToCartBrokerPage() {
  var brokerPage = $('#domain_broker_service_sec');

  $(".add-to-cart-brokerage").on("click", function() {
    brokerPage.find('.add-to-cart-outline').trigger("click");
  });
}

function cartHandler() {
  toggleCart();
  buyNow();
  removeCartItem();
  onUpdateQuantity();
  onUpdatePrivacy();
  onUpdateSSL();
  handleBrokerageModal();
  addToCartBrokerPage();
}

export { cartHandler };
