function handleForm() {
  $("#dns_record_type").change(function(){
    if ($(this).val() === 'MX') {
      $("#dns-record-value-wrapper").addClass("d-none");
      $("#dns-record-mx_value-wrapper").removeClass("d-none");
    } else {
      $("#dns-record-value-wrapper").removeClass("d-none");
      $("#dns-record-mx_value-wrapper").addClass("d-none");
    }
  });

  $("#dns_record_type").trigger("change");

  $("#dns_record_mx_value").click(function(){
    $("#mx-record-modal").modal("show");
  });

  $("#mx-record-modal .btn-create").click(function(e){
    e.preventDefault();

    $("#dns_record_mx_value").val($("#mx_record_priority").val() + " " + $("#mx_record_server").val());

    $("#dns_record_value").val($("#mx_record_server").val());
    $("#dns_record_prio").val($("#mx_record_priority").val());
    $("#mx-record-modal").modal("hide");
  });
}


function validateForm() {
  $.validator.addMethod('IP4Checker', function(value) {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value);
  }, 'Invalid IP address');

  $('.dns-record-form').validate({
    rules: {
      'dns_record[value]': {
        IP4Checker: {
          depends: function(element) {
            return $("#dns_record_type").val() === 'A';
          }
        }
      }
    }
  });
}


function dnsRecordHandler() {
  validateForm();
  handleForm();
}

export { dnsRecordHandler };
