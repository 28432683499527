function paypalHandler() {
  var checkoutContainer = $("#checkout");

  if (checkoutContainer.length == 0 || $("#submit-paypal").length === 0) {
    return;
  }

  paypal.Buttons({
    createOrder: function() {
      return fetch('/confirm_paypal_payment', {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      }).then(function(res) {
        return res.json();
      }).then(function(data) {
        return data.table.id;
      });
    },
    onApprove: function(data) {
      var url = "/billing"

      if ($("#checkout form input[name='error']").length > 0) {
        url = url + "?error=" + $("#checkout form input[name='error']").val()
        // url = url + "?error=all"
      }

      data.error = 'all'

      $.ajax({
        url: url,
        type: "POST",
        data: data,
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))}
      });
    }
  }).render('#submit-paypal');
}

export { paypalHandler };
