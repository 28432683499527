require('jquery-validation');
var Switch = require('weatherstar-switch');

function toggleImportWebsetting() {
  $("input[name='transfers_joker[import_web_setting]']").click(function(e) {
    $(".import_web_setting-description").addClass("d-none");
    $(this).closest(".import_web_setting-option-container").next(".import_web_setting-description").removeClass("d-none");
  })

  $("input[name='transfers_joker[import_web_setting]']:checked").trigger("click");
}

function isFormInvalid() {
  $(".locked-message").removeClass("d-none");

  return ($(".locked-message").length > 0 || !$(".transfer-process-wrapper form").valid())
}

function stepHandler() {
  toggleImportWebsetting();

  $(".transfer-process-wrapper form").validate({
    rules: {
      'transfers_nominet[authorization_code]': {
        'required': true,
        'maxlength': 64,
        'minlength': 6
      },
      'transfers_joker[authorization_code]': {
        'required': true,
        'maxlength': 64,
        'minlength': 6
      }
    },
    messages: {
      'transfers_nominet[authorization_code]': {
        'required': 'An authorization code is required to transfer your domain',
        'maxlength': 'An authorization code should be between 6 and 64 characters long',
        'minlength': 'An authorization code should be between 6 and 64 characters long'
      },
      'transfers_joker[authorization_code]': {
        'required': 'An authorization code is required to transfer your domain',
        'maxlength': 'An authorization code should be between 6 and 64 characters long',
        'minlength': 'An authorization code should be between 6 and 64 characters long'
      }
    }
  })

  $('.title-wrapper').on('click', function() {
    var ele = $(this);
    var contentWrapper = ele.parent().find('.content-wrapper');

    if (!contentWrapper.hasClass('d-none')) return;
    if (isFormInvalid()) return;

    $('.content-wrapper').addClass('d-none');
    contentWrapper.removeClass('d-none');
    
    contentWrapper.find("input[type='checkbox'").each(function(index, checkbox){
      new Switch(
        checkbox, {
          size: 'small', disabled: ($(checkbox).attr("disabled") === "disabled")
        }
      );
    })
  });

  $('#btnContinueStep1').on('click', function() {
    var ele = $(this);
    if (isFormInvalid()) return;

    ele.parent().addClass('d-none');
    $('#titleStep2').trigger('click');
  });

  $('#btnContinueStep2').on('click', function() {
    if (isFormInvalid()) return;

    $(this).parent().addClass('d-none');
    $('#titleStep3').trigger('click');
  });
}

export { stepHandler };
