function handleOptionChange () {
  $(".contact_use_another_dns_service").on("click", ".form-check", ()=>{
    if ($("input[name='contact[use_another_dns_service]']:checked").val() === "true") {
      $(".custom-nameservers").removeClass("d-none")
    } else {
      $(".custom-nameservers").addClass("d-none")
      $(".nameserver-item input").val("")
    }
  })
}

function toggleAddButton() {
  if ($(".nameserver-item.d-none").length == 0) {
    $(".add-more-nameserver").addClass("d-none")
  } else {
    $(".add-more-nameserver").removeClass("d-none")
  }
}

function addMore() {
  $(".add-more-nameserver").on("click", (e)=>{
    e.preventDefault()
    $(".nameserver-item.d-none").first().removeClass("d-none")
    toggleAddButton()
  })
}

function deleteItem() {
  $(".name-servers-wrapper").on("click", ".nameserver-item a", (e)=>{
    e.preventDefault()
    console.log(e.target);
    $(e.target).closest(".nameserver-item").addClass("d-none")
    $(e.target).closest(".nameserver-item").find("input").val("")
    toggleAddButton()
  })
}

function handleCustomeNameservers (){
  handleOptionChange()
  toggleAddButton()
  addMore()
  deleteItem()
}

export { handleCustomeNameservers };
