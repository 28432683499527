function changeProfession() {
  if ($("section.services").length === 0 || $("section.services").data("professions").length === 0) {
    return;
  }

  var count = 0;
  var wordsArray = $("section.services").data("professions");

  $("#professtion-name").text(`${wordsArray[0][1]} ?`)
  $("a.link-to-profession").attr("href", `/best-domain-${wordsArray[0][0]}`)


  setInterval(function () {
    count++;
    
    $("#professtion-name").fadeOut(400, function () {
      let profession = wordsArray[count % wordsArray.length]

      $(this).text(`${profession[1]} ?`).fadeIn(400);
      $("a.link-to-profession").attr("href", `/best-domain-${profession[0]}`)
    });
  }, 6000);
}

// $(".alert" ).fadeOut(3000);

export { changeProfession };
