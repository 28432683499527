function handle2Fa() {
  if ($(".resend-code-count-down").length === 0) {
    return
  }

  let timeleft = 60

  let downloadTimer = setInterval(function(){
    timeleft--
    
    $(".resend-code-count-down").text(`Resend code in ${timeleft} seconds`)

    if(timeleft <= 0) {
      clearInterval(downloadTimer);
      $(".resend-code-count-down").remove()
      $(".resend-link").removeClass("d-none")
    }
  }, 1000);
}

export { handle2Fa }