require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("popper.js");
require("bootstrap");
require("../libs/jquery.validate.min");
require('intl-tel-input');
require('jquery-validation');
require('slick-carousel');

var Cookies = require('js-cookie');
var Switch = require('weatherstar-switch');


import $ from 'jquery';

import flatpickr from "flatpickr";
import intlTelInput from 'intl-tel-input';
import { stripeHandler } from '../components/stripe_handler';
import { paypalHandler } from '../components/paypal_handler';
import { cartHandler } from '../components/cart';
import { favoriteHandler } from '../components/favorite';
import { snipeHandler } from '../components/snipe';
import { passwordMetter } from '../components/password';
import { checkConfirmation } from '../components/check_confirmation';
import { changeProfession } from '../components/change_profession';
import { offer } from '../components/offer';
import { dnsRecordHandler } from '../components/dns_record';
import { scrollTopHandler } from '../components/tos';
import { handlePortfolioDomain } from '../components/portfolio_domain';
import { domainSuggestionHandler } from '../components/domain_suggestion';
import { domainBrokerServiceHandler } from '../components/domain_broker_service';
import { initCoupon } from '../components/coupon';
import { handleCustomeNameservers } from '../components/custom_nameservers'
import { handle2Fa } from '../components/two_fa'
import { load_spinner } from '../components/support_spinner';
import { toggleHandler, sidebarState } from '../components/navbar';
import { stepHandler } from '../components/domain_transfer';
import { subpage } from '../components/subpage';
import { domainSetting } from '../components/domain_setting';
import { autoFocusHandler } from '../components/auto_focus';
import { goToContact } from '../components/goToContact';
import { pullDomainStatus } from '../components/domain_update';
import { onChangePaymentOption } from '../components/payment';
import { dirty_page_handler } from '../components/billing_dirty_check';
import { handleSearchWithOperator } from '../components/search_with_operator';

function onScroll() {
  $(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 100) {
      $("#navbar").removeClass("nav-height");
    }

    if(scroll < 70){
      $("#navbar").addClass("nav-height");
    }
  });
}

function initUserPhoneSelect() {
  if ($("#offer_phone").length > 0) {
    intlTelInput($("#offer_phone")[0], {
      preferredCountries: ["gb", "us"]
    })
  }
}

function batchUpdateInvoice() {
  flatpickr(".invoice-paid-at", {
    dateFormat: "d M Y",
    onChange: (selectedDates, dateStr, instance) => {
      var isFormChange = true;
      const inputName = $(instance.input).attr("name")
      $(`.batch_update_invoice input[name='${inputName}[]']`).val(dateStr)
      $(".batch_update_invoice input[type='submit']").attr("disabled", false)
      window.dirtyPage = true

      dirty_page_handler(isFormChange);
    }
  });
}

document.addEventListener("turbolinks:load", function() {
  $.rails.refreshCSRFTokens();

  batchUpdateInvoice();
  sidebarState();
  onScroll();
  initUserPhoneSelect();
  onChangePaymentOption();
  stripeHandler();
  paypalHandler();
  cartHandler();
  favoriteHandler();
  snipeHandler();
  passwordMetter();
  checkConfirmation();
  changeProfession();
  offer();
  dnsRecordHandler();
  scrollTopHandler();
  handlePortfolioDomain();
  domainSuggestionHandler();
  domainBrokerServiceHandler();
  initCoupon();
  handleCustomeNameservers()
  handle2Fa()
  load_spinner()
  toggleHandler()
  stepHandler()
  subpage();
  domainSetting();
  autoFocusHandler();
  goToContact();
  pullDomainStatus();
  userOpinion();
  handleSearchWithOperator();

  $('[data-toggle="popover"]').popover()

  $.each($(".checkbox-switch"), function(index, checkbox){
    let checkboxSwitch = new Switch(
      checkbox, {
        size: 'small',
        disabled: this.disabled,
        onChange: function(e) {
          console.log(this._el);
          if ($(this._el).hasClass("order_item")) {
            $(this._el).closest("form").submit()
          }
        }
      }
    );

    switches.push(checkboxSwitch)
  })

  $.each($("form"), function(index, form){
    $(form).validate();
  })

  handleCookieMesage()
})

document.addEventListener("turbolinks:before-render", function() {
  $.each(switches, function(index, checkboxSwitch) {
    checkboxSwitch.destroy()
    checkboxSwitch.remove()
  })
  // $(".add-to-cart-brokerage").unbind()
  // $(document).unbind()
})

function handleCookieMesage() {
  if (Cookies.get('allow_cookies') == undefined) {
    $("#cookie-message").animate({bottom: '0'}, 'slow');
  } else {
    initFBPixel();
    $("#cookie-message").remove();
    $("#cookie-info-modal").remove();
  }

  $("#cookie-message .close").click(function(){
    initFBPixel();
    $("#cookie-message").remove();
    Cookies.set('allow_cookies', 'yes', { expires: 365 });
  });
}

function initFBPixel() {
  if (Cookies.get('rails_env') !== 'production') return;

  fbq('init', '1848649621984363'); 
  fbq('track', 'PageView');
}


function userOpinion() {
  $('.slick').not('.slick-initialized').slick({
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 3,
    initialSlide: 1,
    infinite: false,
    centerPadding: '40px',
    infinite: true,
    dots: true,
    responsive: [
          {
            breakpoint: 980, // tablet breakpoint
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              centerPadding: '0px'
            }
          },
          {
            breakpoint: 640, // mobile breakpoint
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '0px'
            }
          }
      ]
  })
  
  // Configure/customize these variables.
  var showChar = 100;  // How many characters are shown by default
  var ellipsestext = "...";
  var moretext = "<button class='btn btn-blue read-more'>Read More</button>";
  var lesstext = "<button class='btn btn-blue read-less mt-2'>Read Less</button>";


  $('.opinion').each(function() {
    var content = $(this).text();

    if(content.length > showChar) {
      var truncatePoint = content.substr(0, showChar).lastIndexOf(' ')
      var c = content.substr(0, truncatePoint);
      var h = content.substr(truncatePoint);
      var html = '</span>' + c + '</span><span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink mt-4">' + moretext + '</a></span>';
      $(this).html(html);
    }

  });

  $(document).on('click', '.morelink', function(){
    if ($(this).closest('.slick-slide').hasClass('slick-current')){
      if($(this).hasClass("less")) {
          $(this).removeClass("less");
          $(this).html(moretext);
      } else {
          $(this).addClass("less");
          $(this).html(lesstext);
      }
      $(this).parent().prev().toggle();
      $(this).prev().toggle();
    }
    return false;
  });

  $(document).on('click', '.slick-active:not(.slick-current)', (e) => {
    var clickedSlide = $(e.target).closest('.slick-slide').first()
    var currentSlide = $('.slick-current').first()
    if (clickedSlide.data('slickIndex') > currentSlide.data('slickIndex')) {
      $('.slick-next').click()
    } else {
      $('.slick-prev').click()
    }
  })
}
