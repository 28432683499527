
function updateCounter($elem, count) {
  if (count === 0) {
    $elem.addClass("d-none");
    return;
  }

  $elem.removeClass("d-none");
  $elem.text(count);

  if (parseInt(count) > 9) {
    $elem.addClass("two-digits");
  } else {
    $elem.removeClass("two-digits");
  }
}

function requestCreate($elem, done = $.noop){
  const domain = $elem.data('domain')
  $.ajax({
    url: $elem.data('url'),
    type: 'POST',
    data: { domain: domain },
    dataType: 'json',
  }).done(function(response) {
    done(response)
  }).fail(function(jqXhr, textStatus) {
    console.log(textStatus);
  });
}

function requestRemove($elem, done = $.noop){
  const url = $elem.data('url')
  const id = $elem.data('id')
  $.ajax({
    url: `${url}/${id}`,
    type: 'DELETE',
    dataType: 'json',
  }).done(function(response) {
    done(response)
  }).fail(function(jqXhr, textStatus) {
    console.log(textStatus);
  });
}

export { updateCounter, requestCreate, requestRemove }
