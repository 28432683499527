function domainBrokerServiceHandler() {
  if ($(".create-acc-form").length == 0) {
    return;
  }

  $('.create-acc-form').validate({
    submitHandler: function(form) {
      var e = document.getElementById("contact_us_country");
      var country = e.options[e.selectedIndex].innerHTML;
      $.ajax({
        url: $(form).attr("action"),
        type: 'POST',
        data: $(form).serialize() + `&contact_us[country_name]=${country}`,
        dataType: 'JSON',
        success: function(data, textStatus, jQxhr) {
          $('#sent_msg').css('visibility', 'visible');
          form.reset();
        },
        fail: function(jqXhr, textStatus, errorThrown) {
          console.log(textStatus);
        }
      })
    }
  })
}

export {
  domainBrokerServiceHandler
};
