import { scrollTopHandler } from '../components/tos';

function handlePortfolioDomain() {
  if ($("#home-portfolio").length == 0) {
    return;
  }

  window.hasMore = true;

  scrollTopHandler(function(){
    if (window.hasMore === false) {
      return;
    }

    let footerHeight = $(".footer .main-content").height();

    if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - footerHeight)) {
      let nextLink = $(".pagination .next a");

      if (nextLink.length > 0 && nextLink.data("loading") !== undefined) {
        return;
      }

      nextLink.data("loading", true)

      if (nextLink.length > 0) {
        $("#spinner").show();
        $.get((nextLink.attr("href") + "&format=js"));
      } else {
        $("#spinner").hide();
        window.hasMore = false;
      }
    }
  });
}

export { handlePortfolioDomain };
