import swal from 'sweetalert'
const Switch = require('weatherstar-switch')
window.DomainSetting = {}

function toggleDomainLockedStatus() {
  const checkbox = $("input.domain-lock-unlock")

  DomainSetting.lockSwitch = new Switch(
    checkbox[0], {
      size: 'small',
      onChange: function(e) {
        lockUnlockDomain(this.getChecked(), this._switch)
      }
    }
  );
}

function lockUnlockDomain(locked, currentSwitch, successCallback = function(){}) {
  let checkbox = $(".domain-lock-unlock")
  let url = `/registrar/domains/${checkbox.data('id')}/unlock`
  let loadingText = 'Unlocking'

  if (locked === true) {
    url = `/registrar/domains/${checkbox.data('id')}/lock`
    loadingText = 'Locking'
  }

  setTimeout(function(){
    $(currentSwitch).addClass("d-none")
    $(".spain-wrapper span").text(loadingText)
    $(".spain-wrapper").removeClass("d-none")
  }, 300)

  $.ajax({
    url: url,
    type: 'PUT',
    dataType: 'json',
  }).done(function(data, textStatus, jQxhr) {
    $(".spain-wrapper").addClass("d-none")
    $(currentSwitch).removeClass("d-none")

    $("#lock-unlock-description").text(data.html)
    successCallback()
  }).fail(function(jqXhr, textStatus, errorThrown) {
    console.log(textStatus);
  });
}

function requestAuthCode() {
  $(".get-auth-code-wrapper").removeClass("d-none")
  $("a.get-auth-code").addClass("d-none")
  $.post(`/registrar/domains/${$(".domain-lock-unlock").data('id')}/request_transfer_auth_code`)
}

function getAuthCode() {
  $("a.get-auth-code").on("click", function(e) {
    e.preventDefault()

    if (DomainSetting.lockSwitch.getChecked() === true) {
      swal({
        title: 'Unlock teav-vibol.com',
        text: 'You must unlock your domain before transferring it away from Domain Snipe. Would you like to unlock your domain now?',
        icon: "warning",
        buttons:{
          cancel: true,
          confirm:{
            text: "Unlock, and continue",
            value: true
          }
        }
      }).then(function(isConfirm){
        if(isConfirm){
          lockUnlockDomain(false, $(".domain-lock-unlock").next(".switch"), requestAuthCode)
        }
      });
    } else {
      requestAuthCode()
    }
  })
}

function domainSetting() {
  toggleDomainLockedStatus()
  getAuthCode()
}

export { domainSetting };
