function goToContact() {
  $('.contact-us-link').on('click', function() {
    var contactUsSection = $("#contact");

    if (contactUsSection.length == 0) {
      return Turbolinks.visit('/#contact');
    }
  
    $('html, body').animate({
      scrollTop: contactUsSection.offset().top
    }, 1000);
  });
}

export { goToContact };
