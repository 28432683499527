var Cookies = require('js-cookie');

function toggleHandler() {
  window.cookies = Cookies

  $('#togglerBtnSidebar').on('click', function() {
    var ele = $(this);
    if (ele.find('.sidebar-toggler-icon').hasClass('sidebar-toggler-icon--x')) {
      $('#profileSidebar, #sidebar').removeClass('d-sm-block');
      $('.has-sidebar').removeClass('has-sidebar');
      ele.find('.sidebar-toggler-icon').removeClass('sidebar-toggler-icon--x');

      Cookies.remove('sidebar_expand');
    } else {
      $('#profileSidebar, #sidebar').addClass('d-sm-block');
      $('main').addClass('has-sidebar');
      ele.find('.sidebar-toggler-icon').addClass('sidebar-toggler-icon--x');

      Cookies.set('sidebar_expand', true);
    }
  });

  $('#togglerBtnSidebarMobile').on('click', function() {
    var ele = $(this);
    if (ele.find('.sidebar-toggler-icon').hasClass('sidebar-toggler-icon--x')) {
      $('#profileSidebar, #sidebar').addClass('d-none');
      $('.has-sidebar').removeClass('has-sidebar');
      ele.find('.sidebar-toggler-icon').removeClass('sidebar-toggler-icon--x');
      $('.sidebar-toggler-wrapper').removeClass('bg-grey'); 
    } else {
      $('#profileSidebar, #sidebar').removeClass('d-none');
      $('main').addClass('has-sidebar');
      ele.find('.sidebar-toggler-icon').addClass('sidebar-toggler-icon--x');
      $('.sidebar-toggler-wrapper').addClass('bg-grey'); 
    }
  });

  var profileSidebar = $('#profileSidebar, #sidebar');
  if (profileSidebar.length > 0) {
    $('.sidebar-toggler-wrapper').removeClass('d-none');
  }

  sidebarHandler();

  $(window).on('resize', function(){
    sidebarHandler();
  });
}

function sidebarHandler() {
  var screenWidth = $(window).width();
  if (screenWidth >= 576) {
    $('.navbar-brand-short-logo').removeClass('d-none');
    return;
  }
  
  var sidebar_toggler_wrapper = $('.sidebar-toggler-wrapper').hasClass('d-none');
  
  if (sidebar_toggler_wrapper) {
    $('.navbar-brand-short-logo').removeClass('d-none');
    return;
  }
  $('.navbar-brand-short-logo').addClass('d-none');
}

function sidebarState() {
  var screenWidth = $(window).width();
  var togglerBtnSidebar = $('#togglerBtnSidebar');

  if (screenWidth < 992 || togglerBtnSidebar.length <= 0) {
    Cookies.remove('sidebar_expand');
    return;
  }

  var sidebar_expand_state = Cookies.get('sidebar_expand');

  if (sidebar_expand_state == "true" || $('#sidebar').length > 0) {
    $('#profileSidebar, #sidebar').addClass('d-sm-block');
    $('#togglerBtnSidebar').find('.sidebar-toggler-icon').addClass('sidebar-toggler-icon--x');
    $('main').addClass('has-sidebar');    
  }
}

export { toggleHandler, sidebarState };
