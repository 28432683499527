const onChangePaymentOption = () => {
  $("input[name='order[payment_method]']").on("click", () => {
    const newActiveTabID = $('input[name="order[payment_method]"]:checked').val()

    console.log(newActiveTabID)

    $('.paymentSelectionTab').removeClass('active')
    $('#tab-' + newActiveTabID).addClass('active')

    if (newActiveTabID === 'paypal') {
      $("#submit-stripe").hide()
    } else {
      $("#submit-stripe").show()
    }

    $("#submit-stripe").val("Pay Now")

    if (newActiveTabID === "send_invoice" || newActiveTabID === "do_not_charge") {
      $("#submit-stripe").val("Proceed")
    }
  })
}

export { onChangePaymentOption };
