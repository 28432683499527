function load_spinner() {
  var support_container = $("#support_container");

  if (support_container.length == 0) {
    return;
  }

  var form = $("#support_container form");

  form.find('input[type="file"]').on('change', (e) => {
    const maxSizeInByte = 30000000  // 30MB
    const elem = e.target
    const files = elem.files
    for (var i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.size > maxSizeInByte) {
          alert("The file is bigger than 30MB")
          elem.value = null
          return
        }
      }
  })

  form.submit(function(event) {
    if (!form.valid()) {
      return;
    }

    $("#spinner-center").modal('show');
  });
}

export { load_spinner };
