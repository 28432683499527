import { updateCounter, requestCreate, requestRemove } from './bookmark'

function updateSnipeBadge(newCounter) {
  updateCounter($(".snipe-counter"), newCounter)
}

function toggleSnipe(){
  $(".snipe-domain, #search-result, #home-portfolio, #tools-domain_expiry_checker").on('click', '.toggle-snipe', function(e){
    const $elem  = $(this);

    if($elem.hasClass('snipped')){
      removeSnipe($elem)
    }else{
      addSnipe($elem)
    }
  })
}

function addSnipe($elem){
  requestCreate($elem, response => {
    $elem.toggleClass("snipped")
    $elem.data('id', response.item.id)
    updateSnipeBadge(response.counter);
  })
}

function removeSnipe($elem){
  requestRemove($elem, response => {
    $elem.toggleClass("snipped")
    updateSnipeBadge(response.counter);
  })
}

function snipeHandler() {
  toggleSnipe();
}

export { snipeHandler };
