function autoFocusHandler() {
  var inputShouldAutofocus = $('.input-should-autofocus');

  if (inputShouldAutofocus.length == 0) {
    return;
  }

  inputShouldAutofocus.focus();
}

export { autoFocusHandler };
