function subpage() {
  var homepage = $('#homepage');

  if (homepage.length > 0) {
    $('.navbar-brand-short-logo').addClass('d-sm-none');
    $('.navbar-brand-logo').addClass('d-sm-block');
    return;
  }

  $('.navbar-brand-logo').removeClass('d-sm-block');
  $('.navbar-brand-short-logo').removeClass('d-sm-none');
}

export { subpage };
