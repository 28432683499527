var bootbox = require("bootbox");
var Cookies = require('js-cookie');

function handleBuyNow() {
  $('.buy-now-form #inputState').change(function () {
    var sellingPrice = $(".buy-now-form").data("sellingPrice")
    var currency = $(this).val();

    $('.buy-now-form  #currencyVal').val(currency);
    $('.buy-now-form  #demo').html("You will be billed the sum of " + sellingPrice);

    if (currency == $("option.main-currency").val()) {
      $('.buy-now-form  #demo').hide();
    } else {
      $('.buy-now-form #demo').show();
    }
  })
}

function gettingUserOffer() {
  $(".makeoffer-form #inputCurrency").keyup(toggleErrorMessage);

  $("button.send-offer").click(function () {
    var offer = $(".makeoffer-form #inputCurrency").val();

    if (offer.length > 0 && 1 * offer >= 100) {
      $("#myModal .making-offer-msg").text("You are about to make an offer of " + $("#myText").val() + offer + " for " + $(".domain-name").text())
      $("#myModal").modal("show");
      $('.modal-backdrop').hide();

      $("#offer_amount_currency").val($("#inputState option:selected").text());
      $("#offer_amount_cents").val(parseInt(offer) * 100);
    }

    toggleErrorMessage()
  });

  $('#myModal .close').click(function () {
    $('#myModal').hide();
  });

  $('.makeoffer-form #inputState').change(function () {
    var currency = $(this).val();
    $('#myText').val(currency);
    toggleErrorMessage()
  })
}


function toggleErrorMessage() {
  var offer = $(".makeoffer-form #inputCurrency").val();

  if (offer.length > 0 && 1 * offer >= 100) {
    $(".offer-msg").addClass("d-none");
  } else {
    var currency = $("#myText").val()
    $(".offer-msg").text("Offer must exceed " + currency + "100");
    $(".offer-msg").removeClass("d-none");
  }
}


function validateOffer() {
  $("#new_offer").validate({
    errorPlacement: function (error, element) {
      if ($(element).closest(".form-group").find(".error-container").length > 0) {
        error.appendTo($(element).closest(".form-group").find(".error-container"));
      } else if ($(element).closest(".form-group").next(".error-container").length > 0) {
        error.appendTo($(element).closest(".form-group").next(".error-container"));
      } else {
        error.appendTo($(element).closest(".form-group"));
      }

    },
    submitHandler: function (form) {
      $.ajax({
        url: $(form).attr("action"),
        type: 'POST',
        data: $(form).serialize(),
        dataType: 'json',
      }).done(function (data, textStatus, jQxhr) {
        $("#myModal").modal("hide");

        if (Cookies.get('allow_cookies') !== undefined) {
          fbq('track', 'Contact');
        }
        bootbox.alert({
          message: "Your offer has been sent",
          backdrop: true
        });
      }).fail(function (error) {
        if (error.status == 422) {
          $('#sent_msg').css("visibility", "initial");
          $('#sent_msg').css("color", "red");
          $('#sent_msg').css("padding-top", "20px")
          $('#sent_msg').removeClass('d-none');
          $('#sent_msg').text(error.responseText);
        } else {
          bootbox.alert({
            message: error.responseJSON.error,
            backdrop: true,
            callback: function () {
              window.location.reload()
            }
          });
        }
      });
    }
  });
}

function offer() {
  handleBuyNow();
  gettingUserOffer();
  validateOffer();
}

export { offer };
