import { updateCounter, requestCreate, requestRemove } from './bookmark'

function updateFavoriteBadge(newCounter) {
  updateCounter($(".favorite-counter"), newCounter)
}

function toggleFav(){
  $(".favorite-domain, #search-result, #home-portfolio, #tools-domain_expiry_checker").on('click', '.toggle-fav', function(e) {
    const $elem  = $(this);
    if ($elem.hasClass('far')) {
      requestCreateFav($elem)
    } else {
      requestRemoveFav($elem)
    }
  })
}

function requestCreateFav($elem){
  requestCreate($elem, response => {
    $elem.toggleClass("far fas")
    $elem.data('id', response.item.id)
    updateFavoriteBadge(response.counter);
  })
}

function requestRemoveFav($elem){
  requestRemove($elem, response => {
    $elem.toggleClass("far fas")
    updateFavoriteBadge(response.counter);
  })
}

function favoriteHandler() {
  toggleFav();
}

export { favoriteHandler };
