window.App = {}

import domainSuggestionChannel from "../channels/domain_suggestion_channel";
import { scrollTopHandler } from '../components/tos';
const io = require('socket.io-client');

function scrollToResult() {
  let resultSection = $("#search");

  if (resultSection.length > 0) {
    $('html, body').animate({
      scrollTop: resultSection.offset().top
    }, 1000);
  }
}

function init(socket) {
  window.initSuggestion = true
  // console.log("suggestion initing ...")
  let result = $("form.search").data("domains");
  window.pageCount = 0;

  let formWrapper = $(".main-search-form")

  if(formWrapper.find("input[name='name']").length > 0 && formWrapper.find("input[name='name']").val().length > 0){
    var keyword = formWrapper.find("form.search").data("keyword");

    let tlds = formWrapper.find("form.search").data("tlds")

    // console.log(tlds)

    if (keyword !== undefined && keyword.length > 0) {
      // console.log('availability')
      socket.emit('availability', {"phrase": keyword, "keyword_only": true, "tlds": tlds});
    } else {
      if (result && result.length ) {
        // console.log('query')
        // console.log(result[0])
        // console.log(tlds)
        socket.emit('query', {"phrase": result[0] , "tlds": tlds});
      }
    }

    socket.emit('more');
    socket.emit('more');
    socket.emit('more');
    socket.emit('more');
    window.pageCount += 5;

    setTimeout(function(){
      while (window.pageCount <= 21) {
        socket.emit('more');
        window.pageCount += 1;
        // console.log(window.pageCount)
      }
    }, 1000);
  }

  let domains = []

  const order_id = window.cookies.get('order_id');
  const favorite_domain_ids = window.cookies.get('favorite_domain_ids');

  socket.on('domains', function(item) {
    // console.log(item)

    if (item instanceof Array === true) {
      if (item[1] === true) {
        item = item[0];
      } else {
        return;
      }
    }

    item = item.toLowerCase();

    if (!result.includes(item)) {
      console.log(window.cookies.get('current_timestamp'))
      result.push(item)
      domainSuggestionChannel.renderSnippet({
        keyword: item,
        order_id: order_id,
        current_timestamp: window.cookies.get('current_timestamp'),
        favorite_domain_ids: favorite_domain_ids
      })
    }
  });
}

function domainSuggestionHandler() {
  let resultContainer = $("#search-result");

  if($('#domain_checks-create').length == 1) {
    // just skip expiry-check-page
    return;
  }

  if (resultContainer.length == 0) {
    return;
  }

  let socket = io('wss://dssearch.domainsnipe.uk/', { transports: ['polling'] });

  window.suggestionSocket = socket

  socket.on("connect", () => {
    // console.log("socket.io connected " + socket.id); // x8WIv7-mJelg7on_ALbx

    if (window.initSuggestion) {
      return
    }

    init(socket)
  });

  socket.on("disconnect", () => {
    // console.log('disconnect'); // undefined
    // socket.connect();
  });

  socket.on('connect_error', function() {
    // console.log("Sorry, there seems to be an issue with the connection!");
  })

  $(document).on("click", "#professions-show .dropdown-profession a.dropdown-item, #professions-show a.profession-link, .btn-stop-domain-suggestion", function(){
    if (window.App.cable) {
      const subscription = window.App.cable.unsubscribe()

      if (subscription) {
        window.App.cable = null
      }
    }

    $("#search-result .desktop-table tbody").removeClass(".suggestions")
    $("#search-result .desktop-table-mobile tbody").removeClass(".suggestions")

    if (window.suggestionSocket) {
      window.suggestionSocket.close()
    }

    // console.log("stop search")
  })

  $(document).on("searchConnected", function(){
    scrollToResult()
    // console.log("initing...")
    $("#spinner").show();

    if (window.initSuggestion === undefined) {
      init(socket)
    }

    // console.log(window.cookies.get('current_timestamp'))

    const keyword = $("#search input[name='name']").val()
    const order_id = window.cookies.get('order_id');
    const favorite_domain_ids = window.cookies.get('favorite_domain_ids');
    
    window.App.cable = domainSuggestionChannel

    domainSuggestionChannel.search_for({
      tlds: $("#search-form-profession form").data("tlds"),
      tld_only: $(".filter-only-tld").data('tldOnly'),
      keyword: keyword,
      order_id: order_id,
      current_timestamp: window.cookies.get('current_timestamp'),
      favorite_domain_ids: favorite_domain_ids
    })
  })

  scrollTopHandler(function(){
    if (window.pageCount >= 21) {
      // No longer needed, close socket in 120s
      setTimeout(function(){
        window.suggestionSocket.close();
      }, 1000 * 120);

      if ($(".desktop-table tr").length > 500) {
        $("#spinner").hide();
      }

      setTimeout(function(){
        if (window.App.cable) {
          const subscription = window.App.cable.unsubscribe()

          if (subscription) {
            window.App.cable = null
          }
        }
      }, 1000 * 120);
      return;
    }

    let footerHeight = $(".footer .main-content").height();

    if ($("#top_level_domains-show").length > 0) {
      footerHeight += $(".tld-list").height();
    }

    if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - footerHeight - 200)) {
      
      if (window.suggestionSocket) {
        $("#spinner").show();
        
        window.suggestionSocket.emit('more');
        window.pageCount += 1;

        // console.log(window.pageCount)

        setTimeout(function(){
          window.suggestionSocket.emit('more');
          window.pageCount += 1;
        }, 100);
      }
    }
  });
}

export { domainSuggestionHandler };
