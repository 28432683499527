function pullDomainStatus() {
  let counter = 0

  setInterval(() => {
    if ($("#domains-index .in-progress").length > 0 && counter < 20) {
      counter++
      $('.domains-wrapper [data-toggle="popover"]').popover('dispose')

      console.log($("form#domain_search").attr("action"))
      console.log(counter)

      $.ajax({
        url: $("form#domain_search").attr("action"),
        method: "GET"
      }).done(function(data, textStatus, jQxhr) {
        $('.domains-wrapper [data-toggle="popover"]').popover()
      });    
    }
  }, 20000);

  updateAutoRenewalPeriod()
}

function updateAutoRenewalPeriod() {
  $("select.auto_renew_period").on("change", function() {
    const period = $(this).val()
    const domainId = $(this).data("domainId")

    $.ajax({
      url: `/registrar/domains/${domainId}/update_renewal_period`,
      type: 'PUT',
      data: { auto_renew_period: period },
      dataType: 'json'
    });
  })
}

export { pullDomainStatus };
